import PageContext from '@context'
import {
  formCookieKey,
  getCookie,
  getEncryptedCookie,
  saveEncryptedValuesToCookieStore,
} from '@cookies'
import {
  isUX1Country,
  loadMarketoForm,
  multilinkToUrl,
  trackFormSubmission,
} from '@helpers'
import useStringTranslation from '@hooks/use-string-translation'
import { Button, LinearProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import renderBlok from '@renderBlok'
import { Icon, MarkdownHandler } from '@system'
import ACC_THEME from '@themes'
import { privacyTextKey } from '@variables/static-strings'
import { Formik } from 'formik'
import { navigate } from 'gatsby'
import React, { useContext, useEffect, useState } from 'react'

const useStyles = makeStyles((theme) => ({
  interactiveFormModuleRoot: {
    border: `2px solid ${theme.palette.background.iris}`,
    color: theme.palette.primary.main,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  interactiveFormModuleContent: {
    flex: 'none',
    padding: '24px 16px',
    [theme.breakpoints.up('md')]: {
      padding: '32px 24px',
    },
  },
  interactiveFormModuleContentTitle: {
    fontSize: '20px',
    fontWeight: theme.typography.fontWeightBold,
    margin: '0',
    [theme.breakpoints.up('md')]: {
      fontSize: '24px',
    },
  },
  interactiveFormModuleForm: {
    display: 'flex',
    flexDirection: 'column',
  },
  interactiveFormModuleFormFields: {
    flex: '1',
    padding: '0 16px 32px 16px',
    [theme.breakpoints.up('md')]: {
      padding: '0 24px 40px 24px',
    },
  },
  interactiveFormModuleFormButton: {
    backgroundColor: theme.palette.background.iris,
    borderRadius: '0',
    color: theme.palette.common.white,
    cursor: 'pointer',
    fontSize: '20px',
    padding: '16px 32px',
    textTransform: 'capitalize',
    width: '100%',
    '&:hover': {
      backgroundColor: theme.palette.background.iris,
    },
    '&:disabled': {
      backgroundColor: theme.palette.background.lightIris,
      cursor: 'not-allowed',
    },
  },
  interactiveFormModuleStepper: {
    display: 'flex',
    marginTop: '32px',
  },
  interactiveFormModuleStepperStep: {
    flex: '1',
    position: 'relative',
    '&:not(:last-of-type):after': {
      backgroundColor: theme.palette.border.gray,
      content: '""',
      height: '2px',
      left: '0',
      position: 'absolute',
      top: '13px',
      width: '100%',
      zIndex: '0',
    },
  },
  interactiveFormModuleStepperStepIcon: {
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    border: `3px solid ${theme.palette.text.plant}`,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    height: '28px',
    marginBottom: '6px',
    position: 'relative',
    width: '28px',
    zIndex: '1',
    '& svg': {
      fill: 'none',
    },
  },
  interactiveFormModuleStepperStepLabel: {
    fontSize: '14px',
    lineHeight: '1.2',
    [theme.breakpoints.up('md')]: {
      fontSize: '16px',
    },
  },
  interactiveFormModulePrivacyText: {
    '& ul': {
      fontSize: '14px',
      listStyleType: 'none',
      margin: '0 0 32px',
      padding: '0 16px',
    },
    [theme.breakpoints.up('md')]: {
      '& ul': {
        padding: '0 24px',
      },
    },
  },
}))

let initialValues = {}
let marketoForm = {}

const InteractiveFormModule = ({ blok, handleNavatticModule }) => {
  const context = useContext(PageContext)
  const privacyText = useStringTranslation(privacyTextKey)

  const { formId, isNotMarketableForm, stepperIsVisible, steps, typUrl } = blok
  const marketoFormId = formId || 1372

  const thankYouPageUrl = typUrl && multilinkToUrl(typUrl)
  const pageDataProduct =
    !!context.children[1].props.blok &&
    context.children[1].props.blok.dataProduct
  const pageConversionType =
    !!context.children[1].props.blok &&
    context.children[1].props.blok.conversionType

  const [activeStep, setActiveStep] = useState(0)
  const [activeStepStepperIsVisible, setActiveStepStepperIsVisible] =
    useState(false)
  const [stepIsValid, setStepIsValid] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const classes = useStyles()

  const stepTitles = steps.map((step) => {
    return step?.instructionText || ''
  })

  const handleValidation = (values, validateNextStep) => {
    const validationStep = validateNextStep ? activeStep + 1 : activeStep

    // Build a validation schema for active form group fields
    const validationSchema = steps[validationStep]?.fields.reduce((acc, cv) => {
      if (cv.component === 'interactiveFormCardSelect') {
        acc[cv.fieldName] = { required: true }
        return acc
      }

      acc[cv.fieldId] = { required: cv.required }
      return acc
    }, {})

    // Evaluate form values against the validation schema
    for (const [key, value] of Object.entries(validationSchema)) {
      if (value.required && (values[key] === undefined || values[key] === '')) {
        setStepIsValid(false)
        return
      }
    }

    setStepIsValid(true)
  }

  const handleNext = (e, values) => {
    e.preventDefault()

    handleValidation(values, true)

    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleInitialValues = () => {
    const savedValues = getCookie(formCookieKey)
    const optinDataCollectionValue =
      isUX1Country(context.locale) && !isNotMarketableForm ? true : undefined

    if (!savedValues) {
      initialValues = { optinDataCollection: optinDataCollectionValue }
      return
    }

    const decryptedValues = getEncryptedCookie(savedValues)
    initialValues = {
      ...decryptedValues,
      optinDataCollection: optinDataCollectionValue,
    }
  }

  const handleMarktoForm = () => {
    loadMarketoForm(marketoFormId, (marketoFormObj) => {
      if (marketoFormObj) {
        marketoForm = marketoFormObj

        marketoFormObj.onSuccess(() => {
          if (handleNavatticModule) {
            trackFormSubmission(marketoFormId, pageDataProduct).then(() => {
              setIsSubmitting(false)
              handleNavatticModule()
            })

            return false
          }

          const handleTyp = () => {
            setIsSubmitting(false)

            if (thankYouPageUrl) {
              navigate(thankYouPageUrl, {
                state: {
                  formId: marketoFormId,
                  conversionType: pageConversionType,
                },
              })
            }
          }

          trackFormSubmission(marketoFormId, pageDataProduct).then(handleTyp())

          return false
        })
      }
    })
  }

  const handleSubmit = (values) => {
    setIsSubmitting(true)

    const _accid = getCookie('_ACCID_')
    if (_accid) {
      values.gAUserID = _accid
    }

    marketoForm.setValues(values)
    window?.qualified('saveFormData', values)
    marketoForm.submit()

    const savedValues = getCookie(formCookieKey)
    saveEncryptedValuesToCookieStore(values, savedValues)
  }

  useEffect(() => {
    if (stepperIsVisible) {
      const activeStepStepperHidden = steps[activeStep].stepperHidden

      if (activeStepStepperHidden) {
        setActiveStepStepperIsVisible(false)
        return
      }

      setActiveStepStepperIsVisible(true)
    }
  }, [activeStep])

  useEffect(() => {
    handleMarktoForm()
    handleInitialValues()
  }, [])

  const renderStepper = () => {
    if (steps?.length > 1) {
      return (
        <div className={classes.interactiveFormModuleStepper}>
          {steps.map((step, step_i) => (
            <div
              className={classes.interactiveFormModuleStepperStep}
              key={`interactive_form_module_step_${step_i}`}
            >
              <div
                className={classes.interactiveFormModuleStepperStepIcon}
                style={{
                  backgroundColor:
                    activeStep >= step_i + 1
                      ? ACC_THEME.palette.text.plant
                      : activeStep === step_i
                      ? ACC_THEME.palette.common.white
                      : ACC_THEME.palette.background.lightSlate,
                  borderColor:
                    activeStep >= step_i
                      ? ACC_THEME.palette.text.plant
                      : ACC_THEME.palette.background.lightSlate,
                }}
              >
                {activeStep >= step_i + 1 && (
                  <Icon
                    className={classes.interactiveFormModuleStepperStepIconSvg}
                  >
                    Checkmark
                  </Icon>
                )}
              </div>
              <div
                className={classes.interactiveFormModuleStepperStepLabel}
                style={{
                  color:
                    activeStep >= step_i
                      ? ACC_THEME.palette.common.black
                      : ACC_THEME.palette.text.tertiary,
                  fontWeight: activeStep >= step_i + 1 ? '700' : '400',
                }}
              >
                {step.stepperLabel}
              </div>
            </div>
          ))}
        </div>
      )
    }

    return null
  }

  return (
    <div className={classes.interactiveFormModuleRoot}>
      <div className={classes.interactiveFormModuleContent}>
        <h3 className={classes.interactiveFormModuleContentTitle}>
          {stepTitles[activeStep]}
        </h3>
        {activeStepStepperIsVisible && renderStepper()}
      </div>
      <Formik
        enableReinitialize
        initialValues={{ ...initialValues }}
        onSubmit={(values) => {
          handleSubmit(values)
        }}
        validate={(values) => handleValidation(values)}
      >
        {({ handleSubmit, values }) => (
          <form
            className={classes.interactiveFormModuleForm}
            onSubmit={handleSubmit}
          >
            <div className={classes.interactiveFormModuleFormFields}>
              {renderBlok(steps[activeStep], isNotMarketableForm)}
            </div>
            {activeStep < steps.length - 1 ? (
              <>
                <Button
                  className={classes.interactiveFormModuleFormButton}
                  onClick={(e) => handleNext(e, values)}
                  disabled={!stepIsValid}
                >
                  Next
                </Button>
              </>
            ) : (
              <>
                {isUX1Country(context.locale) && !isNotMarketableForm && (
                  <div className={classes.interactiveFormModulePrivacyText}>
                    <MarkdownHandler>{privacyText}</MarkdownHandler>
                  </div>
                )}
                {isSubmitting && <LinearProgress />}
                <Button
                  className={classes.interactiveFormModuleFormButton}
                  disabled={!stepIsValid || isSubmitting}
                  type="submit"
                >
                  Submit
                </Button>
              </>
            )}
          </form>
        )}
      </Formik>
    </div>
  )
}

export default InteractiveFormModule
